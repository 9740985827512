import { IconProps } from './types';

export default function HomeIcon({ height, width, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 25.5 25.5"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M686 382.48h-1.1v-8.76h.93a1.78 1.78 0 0 0 1.76-1.24 1.59 1.59 0 0 0-.68-1.81L676 363.43a2 2 0 0 0-2.13 0L663 370.67a1.58 1.58 0 0 0-.69 1.81 1.8 1.8 0 0 0 1.77 1.24h.92v8.76h-1.1a1.68 1.68 0 0 0-1.67 1.68V387a1.68 1.68 0 0 0 1.67 1.68H686a1.68 1.68 0 0 0 1.7-1.68v-2.8a1.68 1.68 0 0 0-1.7-1.72Zm-2.6 0h-3.12v-8.76h3.12Zm-7.73 0v-8.76h3.11v8.76Zm-4.62 0v-8.76h3.12v8.76Zm-7.37-10.43s0-.06.08-.13l10.88-7.2a.54.54 0 0 1 .28-.08.57.57 0 0 1 .25.06l10.91 7.22c.1.07.09.11.08.13s-.11.17-.33.17H664c-.18 0-.27-.11-.29-.17Zm2.76 1.67h3.11v8.76h-3.11ZM686.2 387a.18.18 0 0 1-.17.18h-22.16a.18.18 0 0 1-.17-.18v-2.8a.18.18 0 0 1 .17-.18H686a.18.18 0 0 1 .17.18Z"
                fill="currentColor"
                transform="translate(-662.2 -363.14)"
            />
        </svg>
    );
}
