/**
 * Returns the first character of the first two words
 */
export function getInitials(name: string): string {
    // If given an empty string
    if (name.length < 1) {
        return name;
    }

    const nameParts = name.toUpperCase().split(' ');
    return (nameParts[0] || '').charAt(0) + (nameParts[1] || '').charAt(0);
}
