import { IconProps } from './types';

export default function TrashOutlineIcon({ height, width, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 17.5 18.75"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M707.2 406.55a4.32 4.32 0 0 0-.1-1.38 4.09 4.09 0 0 0-1.27-.09H703a4.13 4.13 0 0 0-1.27.09 4.32 4.32 0 0 0-.1 1.38v.74h5.61Z"
                fill="none"
                transform="translate(-695.65 -403.58)"
            />
            <path
                d="M712.4 407.29h-3.7v-.74c0-1.17 0-1.87-.52-2.42s-1.21-.55-2.35-.55H703c-1.13 0-1.82 0-2.35.55s-.52 1.25-.52 2.42v.74h-3.73a.75.75 0 0 0 0 1.5h.83l.86 7.88c.21 2 .32 3 .77 3.71a3.93 3.93 0 0 0 1.77 1.58c.79.37 1.78.37 3.77.37s3 0 3.77-.37a4 4 0 0 0 1.77-1.58 4.25 4.25 0 0 0 .47-1.4c.11-.59.19-1.33.3-2.31l.86-7.88h.83a.75.75 0 0 0 0-1.5Zm-10.81-.74a4.32 4.32 0 0 1 .1-1.38 4.13 4.13 0 0 1 1.27-.09h2.87a4.09 4.09 0 0 1 1.27.09 4.32 4.32 0 0 1 .1 1.38v.74h-5.61Zm7.63 9.95c-.11 1-.19 1.69-.28 2.2a2.78 2.78 0 0 1-.29.91 2.54 2.54 0 0 1-1.11 1 2.62 2.62 0 0 1-.92.19h-4.44a2.64 2.64 0 0 1-.93-.19 2.54 2.54 0 0 1-1.11-1 2.85 2.85 0 0 1-.28-.91c-.1-.51-.17-1.19-.28-2.2l-.83-7.59h11.3Z"
                fill="currentColor"
                transform="translate(-695.65 -403.58)"
            />
        </svg>
    );
}
